<template>
  <div class="all-users">
    <UsersTable title="All Users"
                :list="filteredUserList" :fields="fields" :toExportFields="toExportFields" :loading="loading" :exportButton="true"
                @update-list="update" @edit="editUser"/>
  </div>
</template>

<script>
import { userService } from '@/services/admin'
import { roles, userStatus } from '@/enums'
import { isAdmin } from '@/helpers/functions'
import UsersTable from '../../../components/admin/UsersTable.vue'
import { User } from '@/models/user'

export default {
  name: 'AdminAllUsers',
  components: {
    UsersTable
},
  data() {
    return {
      userStatus,
      userList: [],
      auxList: [],
      loading: false,
      filteredUserList: []
    }
  },
  computed: {
    fields() {
      return [
        { key: 'id', label: 'ID' },
        { key: 'firstname', label: 'Firstname'},
        { key: 'lastname', label: 'Lastname'},
        { key: 'birthDate', label: 'Birth Date', type: 'date' },
        { key: 'nationality', label: 'Nationality' },
        { key: 'email', label: 'Email' },
        { key: 'countryCode', label: 'Country Code' },
        { key: 'whatsappContact', label: 'Phone number' },
        { key: 'status', label: 'Status'},
        { key: 'unitName', label: 'Unit Name'},
        { key: 'unitType', label: 'Unit Type'},
        { key: 'actions', label: 'Actions', type: 'actions', actions: ['edit'] },
      ]
    },
    toExportFields() {
      return ['id', 'creationDate', 'lastModifiedDate', 'firstname', 'lastname', 'birthDate', 'nationality', 'docNumber', 'genre', 'email'
              , 'countryCode' , 'whatsappContact',
              'username', 'employment', 'ueStatus', 'placeOfBirth', 'fiscalCode',
              'addressCity', 'addressCountry', 'addressStreet', 'addressNumber', 'addressZipCode',
              'tellUs']
    }
  },
  async beforeMount() {
    await this.update()
  },
  methods: {
    editUser(user) {
      this.$store.commit('adminUserStore/setUser', user)
      this.$router.push({ name: 'user-edit', query: { id: user.id } })
    },
    async update() {
      this.loading = true
      this.filteredUserList = []
      this.userList = []
      this.auxList = await userService.getUsers()
      this.auxList.forEach(element => {
        let user = new User(element)
        this.userList.push(user)
        if(!isAdmin(user.roles)){
          this.filteredUserList.push(user)
        }
      });

      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.all-users {
  .table-data {
    .status {
      border-radius: 20px;
      text-align: center;
      color: $white;

      &.new {
        background-color: $dark-blue;
      }
      &.registered {
        background-color: $green;
      }
      &.profile-complete {
        background-color: $dark-gray;
      }
      &.check-document {
        background-color: $orange;
      }
      &.verified {
        background-color: $success;
      }
    }
  }
}
</style>
